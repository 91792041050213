<script>
import { user } from "@/config/api/users";
import { Stack, StackItem } from "vue-stack-grid";

export default {
  data() {
    return {
      allPost: [],
      totalPages: 0,
      title: "",
      items: [
        {
          text: "Users",
          href: "/",
        },
        {
          text: "Normal",
          active: true,
        },
      ],
      rows: 0,
      allPostLoaded: false,
      currentPage: 1,
      perPage: 12,
      fields: [
        { key: "media", sortable: false },
        { key: "details", sortable: false },
      ],
      dataLoading: false,
      playerOptions: {
        height: "280px",
        width: "100%",
        muted: true,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: "",
          },
        ],
      },
    };
  },
  components: { Stack, StackItem },
  computed: {},
  mounted() {},
  created() {
    this.getAllPosts();
    this.getNextPosts();
  },
  methods: {
    getNextPosts() {
      window.onscroll = () => {
        let bottomOfWindow =(window.innerHeight + window.pageYOffset) >= document.body.offsetHeight - 2;
        if (bottomOfWindow) {
          if (this.currentPage < this.totalPages) {
            this.currentPage += 1;
            this.getAllPosts();
          } else {
            this.allPostLoaded = true;
          }
        }
      };
    },
    getAllPosts() {
      this.dataLoading = true;
      const api = user.getAllPosts;
      api.params = {
        userId: this.$store.state.normalUser.selected_user_id,
        page: this.currentPage,
        perPage: this.perPage,
        role: "Normal",
      };
      this.generateAPI(api)
        .then((res) => {
          res.data.docs.forEach((ele) => {
            this.allPost.push(ele);
          });
          this.totalPages = res.data.totalPages;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.dataLoading = false;
          console.log | "Loaded";
        });
    },

    videoSelected(location) {
      this.playerOptions.sources.src = "";
      this.playerOptions.sources[0].src = location;
      this.$refs["post-video-modal"].show();
    },
  },
};
</script>

<template>
  <div class="user-post">
    <div class="row">
      <stack
        :column-min-width="280"
        :gutter-width="8"
        :gutter-height="8"
        :monitor-images-loaded="true"
      >
        <stack-item v-for="item in allPost" :key="item._id">
          <div class="card">
            <div class="card-body">
              <img
                v-if="item.file_type === 'image'"
                class="post-img"
                :src="item.media_files[0].location"
                alt="Media"
              />
              <div v-else>
                <video width="100%" height="240px" controls>
                  <source
                    :src="item.media_files[0].location"
                    type="video/mp4"
                  />
                </video>
              </div>
              <div class="post-caption">
                {{ item.caption.substring(0, 68) }}
                <span v-if="item.caption.length > 68"> ...</span>
              </div>
              <div class="post-status">
                <div>
                  <strong>{{ item.liked_users.length }}</strong> 
                  <i class="ri-thumb-up-line"></i>
                </div>
                <div>
                  <strong> {{ item.views.length }}</strong>
                  <i class="ri-eye-line"></i></div>
                <div>
                  <strong> {{ item.comments.length }}</strong>
                  <i class="ri-chat-1-line"></i>
                </div>
              </div>
              <div class="post-time-stamp">
                {{ moment(item.updatedAt).format("lll") }}
              </div>
            </div>
          </div>
        </stack-item>
      </stack>
    </div>
    <div style="text-align: center; margin: 28px" v-if="dataLoading">
      <pulse-loader color="#e83e8c" />
    </div>
    <div v-if="allPostLoaded" style="text-align: center; margin: 48px">
      There are no more posts
    </div>

    <!--  Modal Start here -->

    <b-modal ref="post-video-modal" size="xl" :hide-footer="true">
      <div style="position: relative">
        <video-player
          class="video-player-box"
          :options="playerOptions"
          :playsinline="true"
        >
        </video-player>
      </div>
    </b-modal>

    <!-- Modal end here -->
  </div>
</template>

<style scoped>
.post-img {
  width: 100%;
}
.post-caption {
  padding: 8px 2px;
  font-size: 14px;
}
.post-time-stamp {
  font-size: 12px;
}
.post-status {
  display: flex;
  justify-content: space-between;
  margin: 4px;
}
.post-status div{
  display: flex;
  background-color: #fafafa;
  padding: 4px 8px;
  border-radius: 8px;
}
.post-status div strong{
  color:#e83e8c;
  margin-right: 8px;
}
</style>